<template>
  <GmapMap
    :center="center"
    :zoom="8"
    map-type-id="terrain"
    style="width: 1200px; height: 1000px"
    @click="handleMapClick"
  >
    <GmapMarker
      :key="index"
      v-for="(m, index) in outlets"
      :position="m"
      :clickable="true"
      :draggable="true"
      @click="handleMarkerClick(m)"
    >
      <GmapInfoWindow :options="{ maxWidth: 200 }">
        {{ m.CardName }}
      </GmapInfoWindow>
      <!-- Add an anchor tag around the GmapMarker content -->
      <a href="#" @click.prevent="handleMarkerClick(m)">
        <!-- Your marker content goes here -->
        <!-- You can customize the content of the marker, e.g., display a label, etc. -->
        <div
          style="
            width: 300px;
            height: 30px;
            background-color: black;
            border-radius: 50%;
          "
        ></div>
      </a>
    </GmapMarker>
  </GmapMap>
</template>

<script>
export default {
  props: {
    outlets: {
      type: Array,
    },
    centerMap: {
      type: Object,
    },
  },
  watch: {
    centerMap: {
      handler: "setCenter",
      immediate: true,
    },
  },
  data() {
    return {
      edited: null,
      center: {},
      nairobiPlaces: [],
    };
  },
  // mounted() {
  //   this.nairobiPlaces = [
  //     {
  //       position: {
  //         lat: -1.3947,
  //         name: "NAIROBI NAT PARK",
  //         id: 18,
  //         lng: 36.9623,
  //       },
  //     },
  //     {
  //       position: {
  //         lat: -1.2864,
  //         lng: 36.8219,
  //       },
  //     },
  //     {
  //       position: {
  //         lat: -1.3664,
  //         lng: 36.7447,
  //       },
  //     },
  //     {
  //       position: {
  //         lat: -1.3272,
  //         lng: 36.7576,
  //       },
  //     },
  //     {
  //       position: {
  //         lat: -1.287,
  //         lng: 36.8236,
  //       },
  //     },
  //   ];

  //   console.log(this.nairobiPlaces);
  //   this.geolocate();
  // },
  methods: {
    geolocate: function () {
      const self = this;
      navigator.geolocation.getCurrentPosition((position) => {
        const marker = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        self.center = marker;
      });
    },
    setCenter(val) {
      this.center = val;
    },
    handleMapClick(event) {
      console.log(event);
      // Get the clicked location from the event object
      const clickedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        CardName: event.latLng.lng(),
      };

      // Do something with the clicked location
      console.log("Map clicked:", clickedLocation);
     
      // You can set the map center to the clicked location if needed
      this.center = clickedLocation;
    },

    handleMarkerClick(marker) {
      console.log("Marker clicked:", marker);
       alert(marker.CardName);
    },
    getPosition(marker) {
      return {
        lat: parseFloat(marker.Latitude),
        lng: parseFloat(marker.Longitude),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>