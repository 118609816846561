<template>
  <v-container fluid>
    <v-toolbar class="accent" dark dense>
      <v-toolbar-title>Customer Map</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="territory.descript"
              label="Region"
              @click="openRegionModal"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-btn color="primary">Filter</v-btn>
          </v-col>
        </v-row>
        <!-- dialog -->
        <v-row>
          <!-- start of territories dialog -->
          <v-dialog v-model="regionDialog" width="650px">
            <v-card>
              <v-toolbar color="accent" dark dense>
                <v-toolbar-title>Select Territory</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="regionDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-treeview
                      color="accent"
                      :activatable="trueValue"
                      :hoverable="trueValue"
                      :selection-type="selectionType"
                      open-all
                      return-object
                      item-text="descript"
                      item-key="id"
                      :items="territories"
                      item-children="children_recursive"
                      :search="search"
                    >
                      <template v-slot:label="{ item }">
                        <div @click="selectRegion(item)">
                          <span style="font-weight: bold">{{
                            item.descript
                          }}</span>
                        </div>
                      </template>
                    </v-treeview>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- end of territories dialog -->
        </v-row>
        <!-- end -->
        <!-- start of map -->
        <v-row>
          <v-col cols="12">
            <map-section
              :outlets="outlets"
              :centerMap="centerMap"
            ></map-section>
          </v-col>
        </v-row>
        <!-- end of map -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MapSection from "./map.vue";

// import gql from "graphql-tag";

export default {
  // apollo: {
  //   // Simple query that will update the 'hello' vue property
  //   form_tabs: gql`
  //     query {
  //       form_tabs {
  //         data {
  //           id
  //           Label
  //           WithTable
  //         }
  //       }
  //     }
  //   `
  // },
  components: {
    MapSection,
  },

  data() {
    return {
      loading: false,
      dialog: false,
      search: null,
      selectionType: "leaf",
      record: {},
      territory: {},
      territories: [],
      trueValue: true,
      outlets: [],
      centerMap: {},
      regionDialog: false,
    };
  },
  methods: {
    openRegionModal() {
      this.regionDialog = true;
    },

    selectRegion(data) {
      this.territory = data;
      const id = data.id;
      const self = this;
      self.loading = true;
      // api call
      this.$store
        .dispatch("get", `/territories/${id}`)
        .then((res) => {
          self.region = res;
          self.loading = false;
          self.regionDialog = false;
          self.centerMap = res.centerMap;
          self.outlets = res.outlets;
          self.dialog = false;
        })
        .catch((err) => {
          console.log(err, "error");
          this.$refs.snackbar.show(err, "red");
        });
      // end
    },
    getTerritories() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/territories`)
        .then((res) => {
          console.log(res);
          self.territories = res.ResponseData;
          self.centerMap = res.ResponseData?.centerMap || {};
          self.outlets = res.ResponseData?.outlets || [];
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getTerritories();
  },
};
</script>

<style lang="scss" scoped>
</style>